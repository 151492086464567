import React, { useState, useEffect } from 'react';
import { Link, graphql } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import { mapEdgesToNodes } from '../lib/helpers';
import { sortByDate, fetchData } from '../lib/youtubeHelpers';

import localize from '../components/localize';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHero from '../components/PageHero';
import FeaturedSection from '../components/FeaturedSection';
import LoadingStateDisplay from '../components/LoadingStateDisplay';
import SingleVideo from '../components/Video/SingleVideo';
import VideosList from '../components/Video/VideosList';
import YoutubePlaylist from '../containers/YoutubePlaylist';

const VideosPage = ({ data, pageContext, path }) => {
  const pageArray = mapEdgesToNodes(data.page);
  const pageInfo = pageArray[0];
  const youtubeChannelUrl = data.youtubeChannel.youtube;

  const { viewAllVideos, loadMoreVideos } = data.videosDictionary;

  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let requestCanceled = false;
    console.log(videos);

    if (videos.length < 1) {
      console.log('no videos ...');

      fetchData(requestCanceled).then((response) => {
        setIsLoading(response.isLoading);
        setIsError(response.isError);
        setVideos(sortByDate(response.items));
      });
    }
  }, [videos]);

  // console.log('is error: ', isError);
  // console.log('is loading: ', isLoading);
  // console.log(videos);
  return (
    <Layout pageContext={pageContext} path={path} siteTitle={data.site.siteTitle}>
      <SEO title={pageInfo.title} />
      <header>
        <PageHero withImage fluidImg={pageInfo.heroImg.asset.fluid} pageTitle={pageInfo.title} />
      </header>

      <LoadingStateDisplay isLoading={isLoading} isError={isError}>
        {videos.length > 0 ? (
          <>
            <FeaturedSection>
              <StyledContainerTitle>
                {data.videosDictionary.latestVideo
                  ? data.videosDictionary.latestVideo
                  : 'Aktuelles Video'}
              </StyledContainerTitle>
              <div style={{ marginBottom: '1.5rem', marginTop: '2rem' }}>
                <SingleVideo video={videos[0]} />
              </div>
            </FeaturedSection>
            <Container style={{ marginTop: '4.875rem' }}>
              <VideosList
                videos={videos.slice(1, videos.length)}
                youtubeChannelUrl={youtubeChannelUrl}
                loadMoreVideos={data.videosDictionary.loadMoreVideos}
                viewMoreOnYT={data.videosDictionary.viewMoreVideosOnYT}
              />
            </Container>
          </>
        ) : (
          ''
        )}
      </LoadingStateDisplay>
    </Layout>
  );
};

const StyledContainerTitle = styled.h2`
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.primary};
  font-weight: 400;
  margin-bottom: 1.5rem;
  margin-top: 0;
  text-align: center;
`;

export const query = graphql`
  query VideosPageQuery {
    site: sanitySiteSettings {
      siteTitle
      siteUrl
    }
    youtubeChannel: sanityFooter {
      youtube
    }
    page: allSanityPage(filter: { id: { eq: "-ff3dfa49-5532-56b2-b2cc-b5e0f8704552" } }) {
      edges {
        node {
          id
          title {
            _type
            de
            en
          }
          heroImg {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    videosDictionary: sanityDictionaryVideo {
      viewAllVideos {
        _type
        de
        en
      }
      loadMoreVideos {
        _type
        de
        en
      }
      viewMoreVideosOnYT {
        _type
        de
        en
      }
      latestVideo {
        _type
        de
        en
      }
    }
  }
`;

export default localize(VideosPage);
