import React, { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import styled from 'styled-components';

import { breakpoints } from '../../theme/mediaQueries';
import { useLoadMore } from '../../lib/hooks';

import VideosListCard from './VideosListCard';
import PageFooter from '../PageFooter';
import OutlineBtnLg from '../Buttons/OutlineBtnLg';

const VideosList = ({ videos, youtubeChannelUrl, loadMoreVideos, viewMoreOnYT }) => {
  const [activeCardId, setActiveCardId] = useState(null);
  const numOfItemsToDisplay = 6;
  const [videosList, loadMore, hasMore, setLoadMore] = useLoadMore(videos, numOfItemsToDisplay);

  // Handle 'load more' button click
  const handleLoadMoreClick = () => {
    setLoadMore(true);
  };

  const handleCardClick = (e) => {
    e.preventDefault();
    setActiveCardId(e.currentTarget.parentNode.id);
  };

  const videosListToDisplay = videosList.map((video) => {
    return (
      <VideosListCard
        key={video.id}
        id={video.id}
        video={video}
        handleCardClick={handleCardClick}
        activeCardId={activeCardId}
      />
    );
  });

  console.log('selected card: ', activeCardId);
  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
      <StyledCardGrid>{videosListToDisplay}</StyledCardGrid>
      <PageFooter>
        {hasMore ? (
          <OutlineBtnLg btnText={loadMoreVideos} as="button" handleClick={handleLoadMoreClick} />
        ) : (
          <OutlineBtnLg btnText={viewMoreOnYT} as="a" href={youtubeChannelUrl} />
        )}
      </PageFooter>
    </div>
  );
};

const StyledCardGrid = styled.section`
  display: flex;
  flex-flow: row wrap;

  @media screen and (min-width: ${breakpoints.md}px) {
    /* 2 cards in a row */
    & > article:nth-of-type(2n + 2) > div:nth-of-type(2) {
      margin-left: calc(-100% - (2 * 1rem));
    }
    /* & > article:nth-of-type(2n + 3) {
      margin-left: 0;
    } */

    & > article > div:nth-of-type(2) {
      width: calc(200% + (2 * 1rem));
    }
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    /* 3 cards in a row */
    & > article:nth-of-type(3n + 2) > div:nth-of-type(2) {
      margin-left: calc(-100% - (2 * 1rem));
    }
    & > article:nth-of-type(3n + 3) > div:nth-of-type(2) {
      margin-left: calc(-200% - (2 * 2 * 1rem));
    }

    /* & > article:nth-of-type(3n + 4) {
      margin-left: 0;
    } */
    & > article > div:nth-of-type(2) {
      width: calc(300% + (2 * 2 * 1rem));
    }
  }
`;

export default VideosList;
