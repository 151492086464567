import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player/youtube';
import { ImPlay2 } from 'react-icons/im';
import styled from 'styled-components';

import { mediaQueries, breakpoints } from '../../theme/mediaQueries';

import PlayerIcon from './PlayerIcon';

const YoutubeSlide = ({
  url,
  previewImgLg,
  previewImgMd,
  previewImgSm,
  isSelected,
  videoNotAvailable,
}) => {
  const bgImg =
    window.innerWidth >= breakpoints.lg
      ? previewImgLg
      : window.innerWidth >= breakpoints.md && window.innerWidth < breakpoints.lg
      ? previewImgMd
      : previewImgSm;

  console.log(url);
  if (url) {
    return (
      <StyledVideoWrapper>
        <StyledReactPlayer
          light={bgImg}
          controls={true}
          width="100%"
          height="100%"
          url={url}
          // playing={isSelected ? isSelected : false}
          playIcon={<PlayerIcon />}
        />
      </StyledVideoWrapper>
    );
  } else {
    return <p>{videoNotAvailable}</p>;
  }
};

const StyledVideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;

  & div.react-player__preview {
    @media screen and (min-width: ${breakpoints.lg}px) {
      /* background-image: url("${bgImgLg}") !important; */
    }
  }
`;
export default YoutubeSlide;
