import React from 'react';
import YoutubeSlide from '../Video/YoutubeSlide';

const SingleVideo = ({ video }) => {
  // Youtube base URL for embedding video
  const ytBaseUrl = `https://www.youtube.com/watch?v=`;

  if (video) {
    const { resourceId, thumbnails } = video.snippet;
    return (
      <YoutubeSlide
        url={ytBaseUrl + resourceId.videoId}
        previewImgLg={thumbnails.high.url}
        previewImgMd={thumbnails.default.url}
        previewImgSm={thumbnails.medium.url}
      />
    );
  }
};

export default SingleVideo;
