import React from 'react';
import { MdPlayCircleOutline } from 'react-icons/md';
import { ImPlay2 } from 'react-icons/im';
import styled from 'styled-components';

const YoutubePreview = ({ video }) => {
  // if (video) console.log(video.snippet);
  return (
    <StyledVideoPreview>
      <StyledVideoItem>
        <StyledVideoItemImage bgUrl={video.snippet && video.snippet.thumbnails.default.url} />
        <StyledVideoItemInner>
          <ImPlay2 />
        </StyledVideoItemInner>
      </StyledVideoItem>
      <StyledVideoItemTitle>
        <h2>{video.snippet && video.snippet.title}</h2>
      </StyledVideoItemTitle>
    </StyledVideoPreview>
  );
};

const StyledVideoPreview = styled.article``;

const StyledVideoItem = styled.div`
  position: relative;
  display: block;
  width: 100%;
  background-color: ${(props) => props.theme.colors.black};
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
  }

  &:hover > span,
  &:active > span {
    opacity: 0.5;
  }
`;

const StyledVideoItemImage = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: ${(props) => `url(${props.bgUrl})`};
  background-size: cover;
  background-position: center center;
  opacity: 0.9;
  transition: opacity 0.3s ease;
`;

const StyledVideoItemInner = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: ${(props) => props.theme.colors.primary};
  background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0) 5%, rgba(0, 0, 0, 0.8) 100%);
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.colors.white};
  }

  ${StyledVideoPreview}:hover & {
    color: ${(props) => props.theme.colors.white};
  }
`;

const StyledVideoItemTitle = styled.div`
  padding: 1rem;
  & h2 {
    color: ${(props) => props.theme.colors.black};
    font-size: 0.8rem;
    font-weight: 500;
    text-transform: none;
    text-align: center;
  }
`;

export default YoutubePreview;
