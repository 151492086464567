import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { IoClose } from 'react-icons/io5';
import styled from 'styled-components';

import { breakpoints } from '../theme/mediaQueries';

import FeaturedSection from '../components/FeaturedSection';
import YoutubePreview from '../components/Video/YoutubePreview';
import YoutubeSlide from '../components/Video/YoutubeSlide';
import YoutubeCarousel from '../components/Video/YoutubeCarousel';
import PageFooter from '../components/PageFooter';
import OutlineBtnLg from '../components/Buttons/OutlineBtnLg';

const YoutubePlaylist = ({
  featuredView,
  nonFtListView,
  allListView,
  numOfVideos,
  showIndicators,
  showArrows,
  youtubeUrl,
  withFooter,
}) => {
  // Number of videos for initial display [Load more feature]
  const numOfItems = 6;

  // Set state for videos lists
  // all videos
  const [items, setItems] = useState([]);
  // If there is a featured section on the page, two objects
  // Set states for featured video object & non-featured videos list
  const [featuredVideo, setFeaturedVideo] = useState({});
  const [nonFeaturedList, setNonFeaturedList] = useState([]);
  // Set states for Load More feature
  // Set state for initially displayed list
  const [list, setList] = useState([]);
  // Set state to trigger load more
  const [loadMore, setLoadMore] = useState(false);
  // Set state to determine if there is more to load
  const [hasMore, setHasMore] = useState(nonFeaturedList.length > numOfItems);

  // Set states for loading & error while fetching videos
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Youtube URL for fetching videos
  const url = `https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet%2CcontentDetails&maxResults=25&playlistId=${process.env.GATSBY_YOUTUBE_PLAYLIST_ID}&key=${process.env.GATSBY_YOUTUBE_API_KEY}`;
  // Youtube base URL for embedding video
  const ytBaseUrl = `https://www.youtube.com/watch?v=`;

  // Function to format date on and sort videos
  const prepareList = (itemslist) => {
    // Convert publishedAt to Date
    itemslist.forEach((item) => {
      item.snippet.publishedAt = new Date(item.snippet.publishedAt);
    });
    // Sort results by publishedAt
    itemslist.sort(function (a, b) {
      return b.snippet.publishedAt - a.snippet.publishedAt;
    });
    return itemslist;
  };

  // Function to split videos list into featured=first video & rest
  const splitList = (itemslist) => {
    const [, ...rest] = itemslist;
    setFeaturedVideo(itemslist[0]);
    setNonFeaturedList(rest);
  };

  // Function to fetch and prepropcess videos list
  const fetchItems = async () => {
    setIsError(false);
    setIsLoading(true);

    try {
      const result = await axios(url)
        .then((result) => {
          const allItems = result.data.items;
          setItems(allItems);
          return allItems;
        })
        .then((allItems) => {
          allItems = prepareList(allItems);
          return allItems;
        })
        .then((allItems) => {
          splitList(allItems);
        });
    } catch (error) {
      setIsError(true);
    }

    setIsLoading(false);
  };

  // Functon to display all or a specific number of videos, e.g. for Carousel
  const createAllList = (items, numOfVideos) => {
    if (numOfVideos) {
      // console.log(numOfVideos);
      return items.slice(0, numOfVideos);
    } else {
      return items;
    }
  };
  // Create list of all/specific number of videos
  const allList = createAllList(items, numOfVideos);

  const [cardIsOpen, setCardIsOpen] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isInactive, setIsInactive] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleCardClick = () => {
    setCardIsOpen(true);
  };

  const handleCloseClick = () => {
    setCardIsOpen(false);
  };

  useEffect(() => {
    if (clicked) {
      setCardIsOpen(false);
    }
  }, [clicked]);
  // console.log(cardIsOpen);
  // Create non-featured videos components list
  const nonFeaturedVideosList = list.map((video) => {
    // console.log(video);
    return (
      <StyledGridCard key={video.id} onClick={handleCardClick}>
        <StyledCardInner>
          <YoutubePreview video={video} />
        </StyledCardInner>
        <StyledCardExpander show={cardIsOpen}>
          <span onClick={() => setClicked(true)}>
            <StyledCloseIcon />
          </span>
        </StyledCardExpander>
      </StyledGridCard>
      // <YoutubeSlide
      //   key={video.id}
      //   url={ytBaseUrl + video.snippet.resourceId.videoId}
      //   previewImgLg={video.snippet.thumbnails.maxres.url}
      //   previewImgMd={video.snippet.thumbnails.standard.url}
      //   previewImgSm={video.snippet.thumbnails.medium.url}
      // />
    );
  });

  // ============= LOAD MORE LOGIC =============
  // Handle 'load more' button click
  const handleLoadMoreClick = () => {
    setLoadMore(true);
  };

  // ============= /LOAD MORE LOGIC =============

  // Fetch and preprocess videos on component mount
  useEffect(() => {
    fetchItems();
  }, []);

  // set state of non-featured videos list on component mount
  useEffect(() => {
    setList([...nonFeaturedList.slice(0, numOfItems)]);
  }, [nonFeaturedList]);

  //
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;

      const isMore = currentLength < nonFeaturedList.length;

      const nextResults = isMore
        ? nonFeaturedList.slice(currentLength, currentLength + numOfItems)
        : [];

      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]);

  // Based on non-featured list state, set hasMore state
  useEffect(() => {
    const isMore = list.length < nonFeaturedList.length;

    setHasMore(isMore);
  }, [list]);

  // console.log(featuredVideo);
  return (
    <main>
      {isLoading && (
        <div>
          <Spinner animation="border">
            <span className="sr-only">Loading ...</span>
          </Spinner>
        </div>
      )}
      {isError && <p>{error}</p>}
      {featuredView && featuredVideo ? (
        <FeaturedSection>
          {/* <YoutubeSlide
            url={ytBaseUrl + featuredVideo.snippet.resourceId.videoId}
            previewImgLg={featuredVideo.snippet.thumbnails.maxres.url}
            previewImgMd={featuredVideo.snippet.thumbnails.standard.url}
            previewImgSm={featuredVideo.snippet.thumbnails.medium.url}
          /> */}
        </FeaturedSection>
      ) : (
        ''
      )}
      {nonFtListView ? <StyledGrid>{nonFeaturedVideosList}</StyledGrid> : ''}
      {allListView && allList ? (
        <YoutubeCarousel items={allList} showIndicators={showIndicators} showArrows={showArrows} />
      ) : (
        ''
      )}
      {withFooter ? (
        <PageFooter>
          {hasMore ? (
            <OutlineBtnLg
              btnText="mehr videos laden"
              as="button"
              handleClick={handleLoadMoreClick}
            />
          ) : (
            <OutlineBtnLg btnText="mehr videos auf youtube ansehen" as="a" href={youtubeUrl} />
          )}
        </PageFooter>
      ) : (
        ''
      )}
    </main>
  );
};

const StyledGrid = styled.div`
  display: flex;
  flex-flow: row wrap;

  @media screen and (min-width: ${breakpoints.md}px) {
    /* 2 cards in a row */
    & > div:nth-of-type(2n + 2) > div:nth-of-type(2) {
      margin-left: calc(-100% - (2 * 1rem));
      background-color: turquoise;
    }
    & > div:nth-of-type(2n + 3) > div:nth-of-type(2) {
      margin-left: 0;
      background-color: pink;
    }

    & > div > div:nth-of-type(2) {
      width: calc(200% + (2 * 1rem));
    }
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    /* 3 cards in a row */
    & > div:nth-of-type(3n + 2) > div:nth-of-type(2) {
      margin-left: calc(-100% - (2 * 1rem));
      background-color: turquoise !important;
    }
    & > div:nth-of-type(3n + 3) > div:nth-of-type(2) {
      margin-left: calc(-200% - (2 * 2 * 1rem));
      background-color: pink;
    }

    & > div:nth-of-type(3n + 4) > div:nth-of-type(2) {
      margin-left: 0;
      background-color: lightgoldenrodyellow;
    }
    & > div > div:nth-of-type(2) {
      width: calc(300% + (2 * 2 * 1rem));
    }
  }
`;

const StyledGridCard = styled.div`
  margin: 1rem;
  width: 100%;
  transition: all 0.2s ease-in-out;

  @media screen and (min-width: ${breakpoints.md}px) {
    width: calc((100% / 2) - (2 * 1rem));
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    width: calc((100% / 3) - (2 * 1rem));
  }

  &:hover > div:first-of-type {
    background-color: turquoise;
    transform: scale(1.05);
  }
`;

const StyledCardInner = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:after {
    transition: all 0.3s ease-in-out;
  }
`;

const StyledCardExpander = styled.div`
  transition: all 0.2s ease-in-out;
  background-color: slategray;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  /* collapsed & expanded */
  margin-top: ${(props) => (props.show ? `1rem` : 0)};
  max-height: ${(props) => (props.show ? `1000px` : 0)};
  min-height: ${(props) => (props.show ? `200px` : 0)};
  overflow: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? 1 : 0)};
`;

const StyledCloseIcon = styled(IoClose)`
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  color: ${(props) => props.theme.colors.white};

  &:hover {
    opacity: 0.9;
  }
`;

export default YoutubePlaylist;
