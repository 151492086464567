import React from 'react';

const LoadingStateDisplay = ({ isLoading, isError, children }) => {
  if (isLoading) {
    return <div>is loading</div>;
  }

  if (isError) {
    return <div>is error</div>;
  }

  return <div>{children}</div>;
};

export default LoadingStateDisplay;
