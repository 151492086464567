import React from 'react';
import { IoPlaySharp } from 'react-icons/io5';
import { ImPlay2 } from 'react-icons/im';
import styled from 'styled-components';

import theme from '../../theme/theme';

const PlayerIcon = () => {
  return (
    <StyledVideoItemInner>
      <ImPlay2 />
    </StyledVideoItemInner>
  );
};

const StyledVideoItemInner = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  color: ${(props) => props.theme.colors.primary};

  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${(props) => props.theme.colors.white};
  }

  /* ${StyledVideoPreview}:hover & {
    color: ${(props) => props.theme.colors.white};
  } */
`;

const StyledIconContainer = styled.span`
  border-radius: 50%;
  border: 3px solid ${(props) => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  height: 3.5rem;

  &:hover {
    border: 3px solid ${(props) => props.theme.colors.primary};
  }
`;

const StyledPlayerIcon = styled(IoPlaySharp)`
  color: ${(props) => props.theme.colors.white};
  width: 2rem;
  height: 2rem;
  line-height: 1;

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

export default PlayerIcon;
