import React from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

const FeaturedVideo = ({ children }) => {
  return (
    <StyledFeaturedSection>
      <Container className="py-5">{children}</Container>
    </StyledFeaturedSection>
  );
};

const StyledFeaturedSection = styled.section`
  background-color: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};
`;

export default FeaturedVideo;
