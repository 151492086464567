import React, { useState, useEffect } from 'react';
import { IoClose } from 'react-icons/io5';
import ReactPlayer from 'react-player/youtube';
import styled from 'styled-components';

import { breakpoints } from '../../theme/mediaQueries';

import YoutubePreview from './YoutubePreview';
import YoutubeSlide from './YoutubeSlide';

const VideosListCard = ({ id, video, handleCardClick, activeCardId }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Youtube base URL for embedding video
  const ytBaseUrl = `https://www.youtube.com/watch?v=`;

  const closeCard = (e) => {
    e.preventDefault();
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(activeCardId === id);
  }, [activeCardId, id]);

  console.log('is open: ', isOpen);
  console.log(video);
  return (
    <StyledCard id={id}>
      <StyledCardInner onClick={handleCardClick} show={isOpen}>
        <YoutubePreview video={video} />
      </StyledCardInner>
      <StyledCardExpander show={isOpen}>
        <IoClose onClick={closeCard} />
        <div style={{ padding: '3rem 3rem' }}>
          <YoutubeSlide
            url={ytBaseUrl + video.snippet.resourceId.videoId}
            previewImgLg={video.snippet.thumbnails.high.url}
            previewImgMd={video.snippet.thumbnails.default.url}
            previewImgSm={video.snippet.thumbnails.medium.url}
          />
        </div>
      </StyledCardExpander>
    </StyledCard>
  );
};

const StyledCard = styled.article`
  margin: 0 1rem 1rem;
  width: 100%;
  transition: all 0.2s ease-in-out;

  @media screen and (min-width: ${breakpoints.md}px) {
    width: calc((100% / 2) - (4 * 1rem));
  }

  @media screen and (min-width: ${breakpoints.lg}px) {
    width: calc((100% / 3) - (3 * 1rem));
  }

  &:hover > div:first-of-type {
    /* background-color: turquoise; */
    transform: scale(1.05);
  }
`;

const StyledCardInner = styled.div`
  width: 100%;
  /* height: 290px; */
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  /* background-color: #eceef1; */
  text-transform: uppercase;
  text-align: center;
  transition: all 0.2s ease-in-out;

  &::after {
    content: '';
    opacity: ${(props) => (props.show ? '1' : 0)};
    display: ${(props) => (props.show ? 'block' : 'none')};
    height: 0;
    width: 0;
    position: absolute;
    bottom: -1rem;
    left: calc(50% - 1rem);
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 1rem solid ${(props) => props.theme.colors.black};
    transition: all 0.2s ease-in-out;
  }
`;

const StyledCardExpander = styled.div`
  transition: all 0.2s ease-in-out;
  background-color: ${(props) => props.theme.colors.black};
  width: 100%;
  position: relative;
  /* display: flex;
  justify-content: center; */

  /* collapsed & expanded */
  margin-top: ${(props) => (props.show ? `1rem` : 0)};
  max-height: ${(props) => (props.show ? `1000px` : 0)};
  min-height: ${(props) => (props.show ? `200px` : 0)};
  overflow: ${(props) => (props.show ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.show ? 1 : 0)};

  & > svg {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    color: ${(props) => props.theme.colors.white};

    &:hover {
      opacity: 0.9;
    }
  }
`;

export default VideosListCard;
