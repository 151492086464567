import React, { useState, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import styled from 'styled-components';

import { sortByDate, fetchData } from '../../lib/youtubeHelpers';

import LoadingStateDisplay from '../LoadingStateDisplay';
import YoutubeSlide from './YoutubeSlide';

const YoutubeCarousel = ({ showIndicators, showArrows, videoNotAvailable }) => {
  const [videos, setVideos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const ytBaseUrl = `https://www.youtube.com/watch?v=`;

  const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;

  const customRenderThumb = (children) =>
    children.map((item) => {
      return (
        <div key={item.key}>
          <img src={item.props.thumbnail.url} />
        </div>
      );
    });

  useEffect(() => {
    let requestCanceled = false;
    console.log(videos);
    console.log(videos.length);
    if (videos.length < 1) {
      console.log('no videos ...');
      console.log(videos);
      
      fetchData(requestCanceled, 5).then((response) => {
        console.log(response);
        setIsLoading(response.isLoading);
        setIsError(response.isError);
        setVideos(sortByDate(response.items));
      });
    }
  }, [videos]);

  return (
    <LoadingStateDisplay isError={isError} isLoading={isLoading}>
      <StyledCarousel
        renderItem={customRenderItem}
        renderThumbs={customRenderThumb}
        showStatus={false}
        showIndicators={showIndicators}
        showArrows={showArrows}
        infiniteLoop={false}
      >
        {videos.map((video) => {
          return (
            <YoutubeSlide
              key={video.id}
              video={video}
              url={ytBaseUrl + video.snippet.resourceId.videoId}
              thumbnail={video.snippet.thumbnails.default}
              previewImgHiRes={video.snippet.thumbnails.high.url}
              previewImgLoRes={video.snippet.thumbnails.medium.url}
              videoNotAvailable={videoNotAvailable}
            />
          );
        })}
      </StyledCarousel>
    </LoadingStateDisplay>
  );
};

const StyledCarousel = styled(Carousel)`
  & .thumbs-wrapper {
  }
  & .thumbs-wrapper .thumbs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
    margin-left: 0.5rem;
    transform: translate3d(0, 0, 0);

    & > .thumb {
      width: calc(100% / 4) !important;
      margin: 0;
      padding: 0 0.5rem 0 0;
      border: 3px solid transparent;

      &.selected {
        /* transform: scale(1.08); */
        border-color: ${(props) => props.theme.colors.primary};
      }
    }
  }
`;

export default YoutubeCarousel;
