import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';

import PageSectionTitle from './PageSectionTitle';

const PageHero = ({ withImage, fluidImg, pageTitle }) => {
  if (withImage) {
    return (
      <StyledBackgroundImage fluid={fluidImg}>
        <PageSectionTitle title={pageTitle} color="white" />
      </StyledBackgroundImage>
    );
  } else {
    return (
      <StyledBackgroundColor>
        <PageSectionTitle title={pageTitle} color="white" />
      </StyledBackgroundColor>
    );
  }
};

const StyledBackgroundColor = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.6);
`;

const StyledBackgroundSectionTitle = styled.h1`
  color: ${(props) => props.theme.colors.white};
`;

export default PageHero;
